.container-fluid {
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: 32px;

  .fondos {
    background-color: #f5f7f9;
    width: 100%;
    height: 100%;

    //// For large screens, center content

    //--max-width-screen: 1920px;
    //--menu-width: 218px;
    //--content-width: calc(var(--max-width-screen) - var(--menu-width));

    @media only screen and (min-width: 1920px) {
      display: flex;
      flex-direction: column;
    }

    & > .pba-title-card,
    & > .card {
      max-width: calc(1920px - 218px);
    }
  }
}
