@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  font-weight: 400;
  .open-sans-semibold {
    font-weight: 600;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-context-menu {
  cursor: context-menu;
}

.border-none {
  border: none;
}

.card-title {
  font-size: $pba-text-head-font-size;
  color: $pba-text-color;
}
