$form-grid-gutter-width: 32px !default;
$grid-gutter-width: 32px !default;

// $spacer: 8px;
$spacer: 0.5rem;
$spacers: (
  0: (
    $spacer * 0
  ),
  1: (
    $spacer * 1
  ),
  2: (
    $spacer * 2
  ),
  3: (
    $spacer * 3
  ),
  4: (
    $spacer * 4
  ),
  5: (
    $spacer * 5
  ),
  6: (
    $spacer * 6
  )
);

$zindex-modal: 999;
$card-border-radius: 16px;
$input-border-radius: 12px;
$btn-border-radius: 16px;
$btn-border-radius-sm: 16px;
$btn-group-border-radius: 16px;
