.btn {
  &:disabled {
    opacity: 1;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-pba-button {
  height: 48px;
}

.pos-btn-pba-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-pba-button {
    height: 48px;
    width: 100%;
  }
}
.btn-primary {
  background-color: $pba-color-btn;
  border-color: $pba-color-btn;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: $btn-border-radius;

  &:hover {
    background-color: $pba-color-btn-hover;
    border-color: $pba-color-btn-hover;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        &:focus {
          background-color: $pba-color-btn;
          border-color: $pba-color-btn;
          box-shadow: none;
        }
      }
    }
  }

  &:disabled {
    background-color: $pba-disabled-color;
    border-color: $pba-disabled-color;
    color: $pba-gray;
  }
}

.btn-outline {
  background: transparent;
  border: solid 1px $pba-gray;
  border-radius: $btn-border-radius;
  color: $pba-gray;

  &:hover {
    color: $pba-text-color;
    border: solid 1px $pba-text-color;
  }

  &:focus {
    box-shadow: 0 0 0 0.3px black;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #404a5e;
        border-color: #404a5e;
        box-shadow: none;
      }
    }
  }

  &:disabled {
    background-color: $pba-disabled-color;
    border-color: $pba-disabled-color;
    color: $pba-gray;
  }
}

.btn-ghost {
  border: 0;
  background: transparent;
  color: $pba-blue;

  &:hover {
    color: #266fca;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #1d5fb0;
      }
    }
  }

  &:disabled {
    color: $pba-disabled-color;
  }

  &.pba-btn-lg,
  &.pba-btn-xl {
    font-weight: 600;
  }
}

.pba-btn-sm {
  padding: 0 8px;
  height: $pba-btn-height-sm;
  font-size: $pba-btn-font-size-sm;
}

.pba-btn-md {
  padding: 0 16px;
  height: $pba-btn-height-md;
  font-size: $pba-btn-font-size-md;
}

.pba-btn-lg {
  height: $pba-btn-height-lg;
  padding: 0 20px;
  font-size: $pba-btn-font-size-lg;
}

.pba-btn-xl {
  height: $pba-btn-height-xl;
  font-size: $pba-btn-font-size-xl;
  padding: 0 32px;
}

.pba-btn-large {
  height: 32px;
  padding: 0 20px;
  min-width: 136px;
  font-size: 14px;
  // border: none;
  border-radius: 4px;

  &:focus {
    box-shadow: none;
    border-radius: 4px;
  }

  &:active {
    border-radius: 4px;
  }
}

.pba-btn-large-searchs {
  height: 32px;
  min-width: 136px;
  font-size: 14px;
  // border: none;
  border-radius: 4px;
  padding: 0 20px;
  margin-top: 8px;

  &:focus {
    box-shadow: none;
    border-radius: 4px;
  }

  &:active {
    border-radius: 4px;
  }
}

@mixin reset-button-pag {
  background-color: white;
  color: #307fe2;
}

.pba-btn--samll--pagination {
  @include reset-button-pag;

  &:active {
    box-shadow: none;
    border-radius: 4px;
    @include reset-button-pag;
  }

  &:focus {
    box-shadow: none;
    border-radius: 4px;
    @include reset-button-pag;
  }

  &:hover {
    @include reset-button-pag;
    border-color: #266fca;
    box-shadow: none;
  }
}
