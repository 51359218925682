// .modal-dialog {
//   width: 280px;
//   height: 216px !important;
//   border-radius: 4px;
// }

// .modal-footer {
//   justify-content: center;
//   border-top: none !important;
// }

// .modal-header {
//   border-bottom: none !important;
// }

// .icon-position {
//   display: flex;
//   -webkit-box-align: center;
//   align-items: center;
//   -webkit-box-pack: end;
//   justify-content: center;
//   padding: 1rem;
//   border-top: 1px solid #dee2e6;
//   border-bottom-right-radius: 0.3rem;
//   border-bottom-left-radius: 0.3rem;
//   margin-bottom: -24px;
// }

// .modal-content {
//   -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.24);

// }

// .size-icon {
//   &:before {
//     font-size: 54px;

//   }
// }

.modal-header {
  padding: 1rem 1rem;
}

.modal-body {
  padding: 1rem;
}

.modal-footer {
  padding: 0.5rem;
}

.modal-backdrop {
  z-index: 99;
}

.modal-80vw {
  width: 80vw;
  max-width: 80vw;
}

.modal-40vw {
  width: 40vw;
  max-width: 40vw;
}

.modal-60vw {
  width: 60vw;
  max-width: 60vw;
}
