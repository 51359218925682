// Text
$pba-text-font-size: 14px;
$pba-text-head-font-size: 22px;
$pba-text-new-head-font-size: 27px;

// Spacers
$pba-spacer: 8;
$pba-spacers: () !default;
$pba-spacers: map-merge(
  (
    sp: $pba-spacer,
    sm: $pba-spacer,
    md: (
      $pba-spacer * 2
    ),
    lg: (
      $pba-spacer * 3
    ),
    xl: (
      $pba-spacer * 4
    ),
    xxl: (
      $pba-spacer * 5
    )
  ),
  $pba-spacers
);

// Buttons
$btn-border-radius: 12px;

$pba-error-border-color: #ff3a55;
$pba-success-border-color: #7acc00;
$pba-error-text-color: #ff3e59;

$pba-btn-height-sp: $pba-spacer * 4px;
$pba-btn-height-sm: $pba-spacer * 4px;
$pba-btn-height-md: $pba-spacer * 5px;
$pba-btn-height-lg: $pba-spacer * 6px;
$pba-btn-height-xl: $pba-spacer * 7px;

$pba-btn-font-size-sp: 9px;
$pba-btn-font-size-sm: 14px;
$pba-btn-font-size-md: 14px;
$pba-btn-font-size-lg: 15px;
$pba-btn-font-size-xl: 15px;

$pba-disabled-color: #d7dce5;
$pba-checked-color: #307fe2;

// Icons
$pba-blue: #307fe2;
$pba-black: #000000;
$pba-white: #ffffff;
$pba-gray: #9da8ba;
$pba-red: #ff3a55;
$pba-yellow: #ffc627;
$pba-text-color: #4e5b73;
$pba-text-label-color: #007de6;
$pba-light-gray: #c8c9c7;
$pba-dark-gray: #4e5b73;
$pba-light-light-gray: #c3c3c3;

// Header Line color
$pba-header: 1.5px solid rgba(227, 226, 226, 1);

$pba-icon-spacer: 8;
$pba-icon-spacers: () !default;
$pba-icon-spacers: map-merge(
  (
    sm: $pba-icon-spacer,

    sp: (
      $pba-icon-spacer + $pba-spacer
    ),
    md: (
      $pba-icon-spacer + $pba-spacer
    ),
    lg: (
      $pba-icon-spacer + $pba-spacer * 2
    ),
    xl: (
      $pba-icon-spacer + $pba-spacer * 3
    ),
    xxl: (
      $pba-icon-spacer + $pba-spacer * 4
    ),
    xxxl: (
      $pba-icon-spacer + $pba-spacer * 5
    )
  ),
  $pba-icon-spacers
);

// Cards
$pba-color-head: #555d6d;
$pba-background: #f5f7f9;

// Table
$pba-table-odd-color: #fbfcfd;
$pba-table-text-head-color: #4e5b73;
$pba-table-line-color: #4e5b73;
$pba-table-select-color: #8792a4;
$pba-table-action-color: #00305f;
$pba-table-action-background: #dfebff;
$pba-table-state-ok-color: #7acc00;
$pba-table-state-ko-color: #ff3a60;
$pba-table-state-warning-color: #ffbb00;

// Tooltip
$pba-tooltip-color: #67748c;

// Dropdown
$pba-dropdown-selected: #dddddd;

//AXESS
$axess-color-blue: #00305f;
$axess-color-blue-focus: #28387b;

// NMS
$nms-color-blue: #007fe9;

$pba-color-base: #003c6e;
$pba-color-btn: $pba-color-base;
$pba-color-btn-hover: #33638b;
