.spinner-table {
  min-height: 728px;

  .spinner-pos {
    position: absolute;
    top: 86px;
    left: 0;
    right: 0;
    bottom: 29px;
    padding: 0 26px;
  }
}
