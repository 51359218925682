/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/scss/bootstrap.scss";

@import 'styles/custom_bootstrap';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'styles/forms';
@import '../node_modules/ngx-toastr/toastr.css';
@import 'styles/mixins/fontface';
@import 'styles/mixins/icons';
@import 'styles/variables';
@import 'styles/variables_icons';
@import 'styles/spinner';
@import 'styles/icons';
@import 'styles/buttons';
@import 'styles/checkbox';
@import 'styles/split';
@import 'styles/cards';
@import 'styles/dropdown';
@import 'styles/input';
@import 'styles/modal';
@import 'styles/pagination';
@import 'styles/layout';
@import 'styles/header';
@import 'styles/menu';
@import 'styles/title_form';
@import 'styles/radio_button';
@import 'styles/main';
@import 'styles/tooltip';
@import 'styles/datepicker';
@import 'styles/error_text';
@import 'styles/select2';
@import 'styles/cdk_group';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

// .select2-container .select2-selection--single {
//   height: 47px !important;

// }

// .select2-container--default .select2-selection--single .select2-selection__rendered {
//   line-height: 45px !important;

// }

// .select2-container--default .select2-selection--single .select2-selection__arrow {
//   top: 8px;
// }

// Dragula estilos

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

// CSS OF TREE

.node-content-wrapper,
.tree-children {
  position: relative;
}

.node-content-wrapper::before,
.tree-children::after {
  position: absolute;
}

.node-content-wrapper::before {
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  height: 28px;
  top: -17px;
  width: 20px;
  left: -28px;
}

.tree-node-level-1 > tree-node-wrapper > .node-wrapper > .node-content-wrapper::before {
  display: none;
}

.tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
  width: 25px;
}

.tree-children::after {
  border-left: 1px solid lightgrey;
  height: 100%;
  top: -15px;
  left: -15px;
}

tree-node:last-child > .tree-node > .tree-children::after {
  border-left: none;
}

.toggle-children {
  z-index: 1;
}

.tree-node-loading {
  display: none;
}

.root {
  height: 100%;
  min-height: 100vh;
}

// To enable scroll only on content

body {
  overflow: hidden;
}

.fondos {
  overflow: auto;
  max-height: calc(100vh - 100px); // consider header space
}

#sidebar-container .menu-options {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 95px);
}
