// @include font-face('hispasat-icons', '../../assets/fonts/hispasat-icons');

@include font-face('hispasat-icons', '../../assets/fonts/hispasat-icons');

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hispasat-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-left {
  margin-left: 16px;
}

.icon-right {
  margin-right: 16px;
}


.axess{
  
@include assign-icon('icon-menu', $pba-icon-menu) {
  font-size: 16px;
}

@include assign-icon('icon-menu', $pba-icon-menu, 'lg') {
}

@include assign-icon('icon-menu', $pba-icon-menu, 'xl') {
}

@include assign-icon('icon-menu', $pba-icon-menu, 'sm');

@include assign-icon('icon-menu-bg-blue', $pba-icon-menu, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-satelite) {
  font-size: 16px;
}

@include assign-icon('icon-satelite', $pba-icon-satelite, 'lg') {
}

@include assign-icon('icon-satelite', $pba-icon-satelite, 'xl') {
}

@include assign-icon('icon-satelite', $pba-icon-satelite, 'sm');

@include assign-icon('icon-satelite-bg-blue', $pba-icon-satelite, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-sphere) {
  font-size: 16px;
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'lg') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'xl') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'sm');

@include assign-icon('icon-sphere-bg-blue', $pba-icon-sphere, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-direccion-ip) {
  font-size: 16px;
}

@include assign-icon('icon-direccion-ip', $pba-icon-direccion-ip, 'lg') {
}

@include assign-icon('icon-direccion-ip', $pba-icon-direccion-ip, 'xl') {
}

@include assign-icon('icon-direccion-ip', $pba-icon-direccion-ip, 'sm');

@include assign-icon('icon-direccion-ip-bg-blue', $pba-icon-direccion-ip, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-undo) {
  font-size: 16px;
}

@include assign-icon('icon-undo', $pba-icon-undo, 'lg') {
}

@include assign-icon('icon-undo', $pba-icon-undo, 'xl') {
}

@include assign-icon('icon-undo', $pba-icon-undo, 'sm');

@include assign-icon('icon-undo-bg-blue', $pba-icon-undo, 'lg', $black, $blue) {
}

@include assign-icon('icon-display', $pba-icon-display) {
  font-size: 16px;
}

@include assign-icon('icon-display', $pba-icon-display, 'lg') {
}

@include assign-icon('icon-display', $pba-icon-display, 'xl') {
}

@include assign-icon('icon-display', $pba-icon-display, 'sm');

@include assign-icon('icon-display-bg-blue', $pba-icon-display, 'lg', $black, $blue) {
}

@include assign-icon(
  'icon-alert-blue',
  $pba-icon-alert,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-unblocked-blue',
  $pba-icon-unblocked,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-menu', $pba-icon-eye) {
  font-size: 16px;
}

@include assign-icon('icon-eye', $pba-icon-eye, 'lg') {
}

@include assign-icon('icon-eye', $pba-icon-eye, 'xl') {
}

@include assign-icon('icon-eye', $pba-icon-eye, 'sm');

@include assign-icon('icon-eye-bg-blue', $pba-icon-eye, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-meter) {
  font-size: 16px;
}

@include assign-icon('icon-meter', $pba-icon-meter, 'lg') {
}

@include assign-icon('icon-meter', $pba-icon-meter, 'xl') {
}

@include assign-icon('icon-meter', $pba-icon-meter, 'sm');

@include assign-icon('icon-meter-bg-blue', $pba-icon-meter, 'lg', $black, $blue) {
}

@include assign-icon('icon-meter-blue', $pba-icon-meter, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-receptor2', $pba-icon-receptor) {
  font-size: 16px;
}

@include assign-icon('icon-receptor2', $pba-icon-receptor, 'lg') {
  font-size: 29px;
}

@include assign-icon('icon-receptor2', $pba-icon-receptor, 'xl') {
}

@include assign-icon('icon-receptor2', $pba-icon-receptor, 'sm');

@include assign-icon('icon-receptor2-bg-blue', $pba-icon-receptor, 'lg', $black, $blue) {
}

@include assign-icon(
  'icon-receptor2-blue',
  $pba-icon-receptor,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-menu', $pba-icon-tab) {
  font-size: 16px;
}

@include assign-icon('icon-tab', $pba-icon-tab, 'lg') {
}

@include assign-icon('icon-tab', $pba-icon-tab, 'xl') {
}

@include assign-icon('icon-tab', $pba-icon-tab, 'sm');

@include assign-icon('icon-tab-bg-blue', $pba-icon-tab, 'lg', $black, $blue) {
}

// onOFF
@include assign-icon('icon-sign-off', $pba-icon-sign-off) {
  font-size: 16px;
}

@include assign-icon('icon-sign-off', $pba-icon-image, 'lg') {
}

@include assign-icon('icon-sign-off', $pba-icon-sign-off, 'xl') {
}

@include assign-icon('icon-sign-off', $pba-icon-sign-off, 'sm');

@include assign-icon('icon-edit-table', $pba-icon-edit, 'lg');

@include assign-icon('icon-sign-off-bg-blue', $pba-icon-sign-off, 'lg', $black, $blue) {
}

//Icono imagen

@include assign-icon('icon-image', $pba-icon-image) {
  font-size: 16px;
}

@include assign-icon('icon-image', $pba-icon-image, 'lg') {
}

@include assign-icon('icon-image', $pba-icon-image, 'xl') {
}

@include assign-icon('icon-image', $pba-icon-image, 'sm');

@include assign-icon('icon-image-bg-blue', $pba-icon-image, 'lg', $black, $blue) {
}

//Icono flecha

@include assign-icon('icon-down', $pba-icon-down) {
  font-size: 16px;
}

@include assign-icon('icon-down', $pba-icon-down, 'lg') {
}

@include assign-icon('icon-down', $pba-icon-down, 'xl') {
}

@include assign-icon('icon-down', $pba-icon-down, 'sm');

@include assign-icon('icon-down-bg-blue', $pba-icon-down, 'lg', $black, $blue) {
}

//Icono help

@include assign-icon('icon-help', $pba-icon-help) {
  font-size: 16px;
}

@include assign-icon('icon-help', $pba-icon-help, 'lg') {
}

@include assign-icon('icon-help', $pba-icon-help, 'xl') {
}

@include assign-icon('icon-help', $pba-icon-help, 'sm');

@include assign-icon('icon-help-bg-blue', $pba-icon-help, 'lg', $black, $blue) {
}

//icono soporte

@include assign-icon('icon-support', $pba-icon-support) {
  font-size: 16px;
}

@include assign-icon('icon-support', $pba-icon-support, 'lg') {
}

@include assign-icon('icon-support', $pba-icon-support, 'xl') {
}

@include assign-icon('icon-support', $pba-icon-support, 'sm');

@include assign-icon('icon-support-bg-blue', $pba-icon-support, 'lg', $black, $blue) {
}

//icono connection

@include assign-icon('icon-connection', $pba-icon-connection) {
  font-size: 16px;
}

@include assign-icon('icon-connection', $pba-icon-connection, 'lg') {
  font-size: 16px;
  margin-right: 4px;
}

@include assign-icon('icon-connection', $pba-icon-connection, 'xl') {
}

@include assign-icon('icon-connection', $pba-icon-connection, 'sm');

@include assign-icon('icon-connection-bg-blue', $pba-icon-connection, 'lg', $black, $blue) {
  font-size: 16px;
}

//icono wrench

@include assign-icon('icon-wrench', $pba-icon-wrench) {
  font-size: 16px;
}

@include assign-icon('icon-wrench', $pba-icon-wrench, 'lg') {
  font-size: 16px;
  margin-right: 8px;
}

@include assign-icon('icon-wrench', $pba-icon-wrench, 'xl') {
}

@include assign-icon('icon-wrench', $pba-icon-wrench, 'sm');

@include assign-icon('icon-wrench-bg-blue', $pba-icon-wrench, 'lg', $black, $blue) {
  font-size: 16px;
}

//icono history ip

@include assign-icon('icon-history-ip', $pba-icon-history-ip) {
  font-size: 16px;
}

@include assign-icon('icon-history-ip', $pba-icon-history-ip, 'lg') {
  font-size: 16px;
  margin-right: 8px;
}

@include assign-icon('icon-history-ip', $pba-icon-history-ip, 'xl') {
}

@include assign-icon('icon-history-ip', $pba-icon-history-ip, 'sm');

@include assign-icon('icon-history-ip-bg-blue', $pba-icon-history-ip, 'lg', $black, $blue) {
  font-size: 16px;
}

//price-tags

@include assign-icon('icon-price-tags', $pba-icon-price-tags) {
  font-size: 16px;
}

@include assign-icon('icon-price-tags', $pba-icon-price-tags, 'lg') {
  font-size: 16px;
  margin-right: 3px;
}

@include assign-icon('icon-price-tags', $pba-icon-price-tags, 'xl') {
}

@include assign-icon('icon-price-tags', $pba-icon-price-tags, 'sm');

@include assign-icon('icon-price-tags-bg-blue', $pba-icon-price-tags, 'lg', $black, $blue) {
  font-size: 16px;
}
//Icono usuario

@include assign-icon('icon-user', $pba-icon-user) {
  font-size: 16px;
}

@include assign-icon('icon-user', $pba-icon-user, 'lg') {
}

@include assign-icon('icon-user', $pba-icon-user, 'xl') {
}

@include assign-icon('icon-user', $pba-icon-user, 'sm');

@include assign-icon('icon-user-bg-blue', $pba-icon-user, 'lg', $black, $blue) {
}

//Icono router

@include assign-icon('icon-router', $pba-icon-router) {
  font-size: 16px;
}

@include assign-icon('icon-router', $pba-icon-router, 'lg') {
}

@include assign-icon('icon-router', $pba-icon-router, 'xl') {
}

@include assign-icon('icon-router', $pba-icon-router, 'sm');

@include assign-icon('icon-router-bg-blue', $pba-icon-router, 'lg', $black, $blue) {
}

//Icono router con señal

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi) {
  font-size: 16px;
}

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi, 'lg') {
}

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi, 'xl') {
}

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi, 'sm');

@include assign-icon('icon-router-wifi-bg-blue', $pba-icon-router-wifi, 'lg', $black, $blue) {
}

//Icono change platform

@include assign-icon('icon-change-platform', $pba-icon-change-platform) {
  font-size: 16px;
}

@include assign-icon('icon-change-platform', $pba-icon-change-platform, 'lg') {
}

@include assign-icon('icon-change-platform', $pba-icon-change-platform, 'xl') {
}

@include assign-icon('icon-change-platform', $pba-icon-change-platform, 'sm');

@include assign-icon('icon-change-platform-bg-blue', $pba-icon-change-platform, 'lg', $black, $blue) {
}

//Icono parabolica

@include assign-icon('icon-parabolica', $pba-icon-parabolica) {
  font-size: 16px;
}

@include assign-icon('icon-parabolica', $pba-icon-parabolica, 'lg') {
}

@include assign-icon('icon-parabolica', $pba-icon-parabolica, 'xl') {
}

@include assign-icon('icon-parabolica', $pba-icon-parabolica, 'sm');

@include assign-icon('icon-parabolica-bg-blue', $pba-icon-parabolica, 'lg', $black, $blue) {
}

//Icono antena

@include assign-icon('icon-antena', $pba-icon-antena) {
  font-size: 16px;
}

@include assign-icon('icon-antena', $pba-icon-antena, 'lg') {
}

@include assign-icon('icon-antena', $pba-icon-antena, 'xl') {
}

@include assign-icon('icon-antena', $pba-icon-antena, 'sm');

@include assign-icon('icon-antena-bg-blue', $pba-icon-antena, 'lg', $black, $blue) {
}

//Logo

@include assign-icon('icon-logo', $pba-icon-logo) {
  font-size: 16px;
}

@include assign-icon('icon-logo', $pba-icon-logo, 'lg') {
}

@include assign-icon('icon-logo', $pba-icon-logo, 'xl') {
}

@include assign-icon('icon-logo', $pba-icon-logo, 'sm');

@include assign-icon('icon-logo-bg-blue', $pba-icon-logo, 'lg', $black, $blue) {
}

// Candado

@include assign-icon('icon-candado', $pba-icon-candado) {
  font-size: 16px;
}

@include assign-icon('icon-candado', $pba-icon-candado, 'lg') {
}

@include assign-icon('icon-candado', $pba-icon-candado, 'xl') {
}

@include assign-icon('icon-candado', $pba-icon-candado, 'sm');

@include assign-icon('icon-candado-bg-blue', $pba-icon-candado, 'lg', $black, $blue) {
}

//logo ok

@include assign-icon('icon-logok', $pba-icon-logok) {
  font-size: 16px;
}

@include assign-icon('icon-logok', $pba-icon-logok, 'lg') {
}

@include assign-icon('icon-logok', $pba-icon-logok, 'xl') {
}

@include assign-icon('icon-logok', $pba-icon-logok, 'sm');

@include assign-icon('icon-logok-bg-blue', $pba-icon-logok, 'lg', $black, $blue) {
}

//Calendario

@include assign-icon('icon-calendar', $pba-icon-calendar) {
  font-size: 16px;
}

@include assign-icon('icon-calendar', $pba-icon-calendar, 'lg') {
}

@include assign-icon('icon-calendar', $pba-icon-calendar, 'xl') {
}

@include assign-icon('icon-calendar', $pba-icon-calendar, 'sm');

@include assign-icon('icon-calendar-bg-blue', $pba-icon-calendar, 'lg', $black, $blue) {
}

// Table Icons
@include assign-icon(
  'icon-delete-blue',
  $pba-icon-delete,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-delete-blue',
  $pba-icon-delete,
  'sm',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 22px;
}

// Table Icons
@include assign-icon('icon-clock-blue', $pba-icon-clock, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-check-blue', $pba-icon-check, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon(
  'icon-sphere-blue',
  $pba-icon-sphere,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-direccion-ip-blue',
  $pba-icon-direccion-ip,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

// // todo
// @include assign-icon(
//   'icon-direccion-ip-blue',
//   $pba-icon-direccion-ip,
//   'md',
//   $axess-color-blue,
//   $pba-table-action-background
// ) {
//   font-size: 27px;
// }

@include assign-icon('icon-undo-blue', $pba-icon-undo, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-eye-blue', $pba-icon-eye, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-qts-blue', $pba-icon-cogs, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-tab-blue', $pba-icon-tab, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon(
  'icon-sign-off-blue',
  $pba-icon-sign-off,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-wifi-off-blue',
  $pba-icon-wifi,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-force-activation-blue',
  $pba-icon-force-activation,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-change-platform-blue',
  $pba-icon-change-platform,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-assignment',
  $pba-icon-assignment,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 30px;
}

@include assign-icon(
  'icon-sign-off-red',
  $pba-icon-sign-off,
  'md',
  $pba-table-state-ko-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-wifi-off-red',
  $pba-icon-wifi,
  'md',
  $pba-table-state-ko-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

// @include assign-icon('icon-confirm-blue', $pba-icon-confirm, 'md', $axess-color-blue, $pba-table-action-background) {
//   font-size: 27px;
// }

@include assign-icon(
  'icon-router-wifi-blue',
  $pba-icon-router-wifi,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-change-platform-blue',
  $pba-icon-change-platform,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-terminal-wifi', $pba-icon-terminal-wifi, 'lg') {
  color: #307fe2;
  background: #fff;
  font-size: 34px;
}

@include assign-icon('icon-edit-blue', $pba-icon-edit, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-edit-blue-table', $pba-icon-edit) {
  font-size: 16px;
}

@include assign-icon('icon-qts-blue', $pba-icon-qts, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon(
  'icon-times-outline-blue',
  $pba-icon-times-outline,
  'sm',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 14px;
  padding: 4px;
}

@include assign-icon(
  'icon-monitoring-blue',
  $pba-icon-monitoring,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-wrench-blue',
  $pba-icon-wrench,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-monitoring-prtg-blue',
  $pba-icon-monitoring,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}




// @include assign-icon(
//   'icon-alert-blue',
//   $pba-icon-delete,
//   'md',
//   $nms-color-blue,
//   $pba-table-action-background
// ) {
//   font-size: 27px;
// }

@include assign-icon(
  'icon-share-blue',
  $pba-icon-share-little,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon(
  'icon-search-blue',
  $pba-icon-search,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon('icon-play2-blue', $pba-icon-play2, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
  opacity: 0.9;
}
@include assign-icon(
  'icon-forward-blue',
  $pba-icon-forward,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon(
  'icon-download-blue',
  $pba-icon-download,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon(
  'icon-config_colums-blue',
  $pba-icon-config_colums,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon('icon-plus-blue', $pba-icon-plus, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 15px;
  opacity: 0.9;
}

@include assign-icon('icon-map-blue', $pba-icon-map, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 15px;
  opacity: 0.9;
}

//Info

@include assign-icon('icon-info', $pba-icon-info) {
  font-size: 16px;
}

@include assign-icon('icon-info', $pba-icon-info, 'lg') {
}

@include assign-icon('icon-info', $pba-icon-info, 'xl') {
}

@include assign-icon('icon-info', $pba-icon-info, 'sm');

@include assign-icon('icon-info-bg-blue', $pba-icon-info, 'lg', $black, $blue) {
}

// Danger

@include assign-icon('icon-danger', $pba-icon-danger) {
  font-size: 16px;
}

@include assign-icon('icon-danger', $pba-icon-danger, 'lg') {
}

@include assign-icon('icon-danger', $pba-icon-danger, 'xl') {
}

@include assign-icon('icon-danger', $pba-icon-danger, 'sm');

@include assign-icon('icon-danger-bg-blue', $pba-icon-danger, 'lg', $black, $blue) {
}

//Users

@include assign-icon('icon-users', $pba-icon-users) {
  font-size: 16px;
}

@include assign-icon('icon-users', $pba-icon-users, 'lg') {
}

@include assign-icon('icon-users', $pba-icon-users, 'xl') {
}

@include assign-icon('icon-users', $pba-icon-users, 'sm');

@include assign-icon('icon-users-bg-blue', $pba-icon-users, 'lg', $black, $blue) {
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked) {
  font-size: 16px;
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked, 'lg') {
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked, 'xl') {
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked, 'sm');

@include assign-icon('icon-unblocked-bg-blue', $pba-icon-unblocked, 'lg', $black, $blue) {
}

// chart

@include assign-icon('icon-chart', $pba-icon-chart) {
  font-size: 16px;
}

@include assign-icon('icon-chart', $pba-icon-chart, 'lg') {
}

@include assign-icon('icon-chart', $pba-icon-chart, 'xl') {
}

@include assign-icon('icon-chart', $pba-icon-chart, 'sm');

@include assign-icon('icon-chart-bg-blue', $pba-icon-chart, 'lg', $black, $blue) {
}

// receptor

@include assign-icon('icon-receptor', $pba-icon-receptor) {
  font-size: 16px;
}

@include assign-icon('icon-receptor', $pba-icon-receptor, 'lg') {
}

@include assign-icon('icon-receptor', $pba-icon-receptor, 'sm');

@include assign-icon('icon-receptor-bg-blue', $pba-icon-receptor, 'lg', $black, $blue) {
}

@include assign-icon('icon-receptor', $pba-icon-receptor, 'xl') {
}
// file-text2

@include assign-icon('icon-file-text2', $pba-icon-file-text2) {
  font-size: 16px;
}

@include assign-icon('icon-file-text2', $pba-icon-file-text2, 'lg') {
}

@include assign-icon('icon-file-text2', $pba-icon-file-text2, 'sm');

@include assign-icon('icon-file-text2-bg-blue', $pba-icon-file-text2, 'lg', $black, $blue) {
}

// stack

@include assign-icon('icon-stack', $pba-icon-stack) {
  font-size: 16px;
}

@include assign-icon('icon-stack', $pba-icon-stack, 'lg') {
}

@include assign-icon('icon-stack', $pba-icon-stack, 'sm');

@include assign-icon('icon-stack', $pba-icon-stack, 'xl');

@include assign-icon('icon-stack-bg-blue', $pba-icon-stack, 'lg', $black, $blue) {
}

// left arrow

@include assign-icon('icon-map', $pba-icon-map) {
  font-size: 16px;
}

@include assign-icon('icon-map', $pba-icon-map, 'lg') {
}

@include assign-icon('icon-map', $pba-icon-map, 'xl') {
}

@include assign-icon('icon-map', $pba-icon-map, 'sm');

@include assign-icon('icon-map-bg-blue', $pba-icon-map, 'lg', $black, $blue) {
}

// left arrow

@include assign-icon('icon-map2', $pba-icon-map2) {
  font-size: 16px;
}

@include assign-icon('icon-map2', $pba-icon-map2, 'lg') {
}

@include assign-icon('icon-map2', $pba-icon-map2, 'xl') {
}

@include assign-icon('icon-map2', $pba-icon-map2, 'sm');

@include assign-icon('icon-map2-bg-blue', $pba-icon-map2, 'lg', $black, $blue) {
}

// barra para listas en terminales
@include assign-icon('icon-rows', $pba-icon-rows) {
  font-size: 16px;
}

@include assign-icon('icon-rows', $pba-icon-rows, 'lg') {
}


@include assign-icon('icon-rows', $pba-icon-rows, 'xl') {
}

@include assign-icon('icon-rows', $pba-icon-rows, 'sm');

@include assign-icon('icon-rows-bg-blue', $pba-icon-rows, 'lg', $black, $blue) {
}

// simbolo de añadir

@include assign-icon('icon-add', $pba-icon-add) {
  font-size: 16px;
}

@include assign-icon('icon-add', $pba-icon-add, 'lg') {
}

@include assign-icon('icon-add', $pba-icon-add, 'xl') {
}

@include assign-icon('icon-add', $pba-icon-add, 'sm');

@include assign-icon('icon-add-bg-blue', $pba-icon-add, 'lg', $black, $blue) {
}

@include assign-icon('icon-add', $pba-icon-add, 'xxxl') {
}

// reset

@include assign-icon('icon-reset', $pba-icon-reset) {
  font-size: 16px;
}

@include assign-icon('icon-reset', $pba-icon-reset, 'lg') {
}

@include assign-icon('icon-reset', $pba-icon-reset, 'xl') {
}

@include assign-icon('icon-reset', $pba-icon-reset, 'sm');

@include assign-icon('icon-reset-bg-blue', $pba-icon-reset, 'lg', $black, $blue) {
}

// Danger
// @include assign-icon('icon-dangers', $pba-icon-dangers) {
//   font-size: 16px;
// }

// @include assign-icon('icon-dangers', $pba-icon-dangers, "lg") {}

// @include assign-icon('icon-dangers', $pba-icon-dangers, "xl") {}

// @include assign-icon('icon-dangers', $pba-icon-dangers, "sm");

// @include assign-icon('icon-dangers-bg-blue', $pba-icon-dangers, "lg", $black, $blue) {}

// rectangulo danger

@include assign-icon('icon-dangers', $pba-icon-dangers) {
  font-size: 16px;
}

@include assign-icon('icon-dangers', $pba-icon-dangers, 'lg') {
}

@include assign-icon('icon-dangers', $pba-icon-dangers, 'xl') {
}

@include assign-icon('icon-dangers', $pba-icon-dangers, 'sm');

@include assign-icon('icon-dangers-bg-blue', $pba-icon-dangers, 'lg', $black, $blue) {
}

// icono download
@include assign-icon('icon-download', $pba-icon-download) {
  font-size: 16px;
}

@include assign-icon('icon-download', $pba-icon-download, 'lg') {
}

@include assign-icon('icon-download', $pba-icon-download, 'xl') {
}

@include assign-icon('icon-download', $pba-icon-download, 'sm');

@include assign-icon('icon-download-bg-blue', $pba-icon-download, 'lg', $black, $blue) {
}

// icono forward
@include assign-icon('icon-forward', $pba-icon-download) {
  font-size: 16px;
}

// icono config colum
@include assign-icon('icon-config_colums', $pba-icon-config_colums) {
  font-size: 16px;
}

@include assign-icon('icon-config_colums', $pba-icon-config_colums, 'lg') {
}

@include assign-icon('icon-config_colums', $pba-icon-config_colums, 'xl') {
}

@include assign-icon('icon-config_colums', $pba-icon-config_colums, 'sm');

@include assign-icon('icon-config_colums-bg-blue', $pba-icon-config_colums, 'lg', $black, $blue) {
}

// icono migrate unico
@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico) {
  font-size: 16px;
}

@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico, 'lg') {
}

@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico, 'xl') {
}

@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico, 'sm');

@include assign-icon('icon-migrate-unico-bg-blue', $pba-icon-migrate-unico, 'lg', $black, $blue) {
}

// icono migrate vths
@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts) {
  font-size: 16px;
}

@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts, 'lg') {
}

@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts, 'xl') {
}

@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts, 'sm');

@include assign-icon('icon-migrate-vhts-bg-blue', $pba-icon-migrate-vhts, 'lg', $black, $blue) {
}

// icono plus
@include assign-icon('icon-plus', $pba-icon-plus) {
  font-size: 16px;
}

@include assign-icon('icon-plus', $pba-icon-plus, 'lg') {
}

@include assign-icon('icon-plus', $pba-icon-plus, 'xl') {
}

@include assign-icon('icon-plus', $pba-icon-plus, 'sm');

@include assign-icon('icon-plus-bg-blue', $pba-icon-plus, 'lg', $black, $blue) {
  font-size: 16px;
}

@include assign-icon('icon-forward', $pba-icon-forward, 'lg') {
}

@include assign-icon('icon-forward', $pba-icon-forward, 'xl') {
}

@include assign-icon('icon-forward', $pba-icon-forward, 'sm');

@include assign-icon('icon-forward-bg-blue', $pba-icon-forward, 'lg', $black, $blue) {
}

// icono circulo
@include assign-icon('icon-uniE900', $pba-icon-uniE900) {
  font-size: 16px;
}

@include assign-icon('icon-uniE900', $pba-icon-uniE900, 'lg') {
}

@include assign-icon('icon-uniE900', $pba-icon-uniE900, 'xl') {
}

@include assign-icon('icon-uniE900', $pba-icon-uniE900, 'sm');

@include assign-icon('icon-uniE900-bg-red', $pba-icon-uniE900, 'lg', $red, $red) {
}

// icono upload
@include assign-icon('icon-upload', $pba-icon-upload) {
  font-size: 16px;
}

@include assign-icon('icon-upload', $pba-icon-upload, 'lg') {
}

@include assign-icon('icon-upload', $pba-icon-upload, 'xl') {
}

@include assign-icon('icon-delete', $pba-icon-delete, 'xl') {
}

@include assign-icon('icon-delete', $pba-icon-delete, 'table') {
  font-size: 5px;
}

@include assign-icon('icon-delete', $pba-icon-delete, 'lg') {
}

@include assign-icon('icon-upload', $pba-icon-upload, 'sm');

@include assign-icon('icon-upload-bg-blue', $pba-icon-upload, 'lg', $black, $blue) {
}

// icono test

@include assign-icon('icon-test', $pba-icon-test) {
  font-size: 16px;
}

@include assign-icon('icon-test', $pba-icon-test, 'lg') {
}

@include assign-icon('icon-test', $pba-icon-test, 'xl') {
}

@include assign-icon('icon-test', $pba-icon-test, 'sm');

@include assign-icon('icon-test-bg-blue', $pba-icon-test, 'lg', $black, $blue) {
}

//Check

@include assign-icon('icon-check', $pba-icon-check) {
  font-size: 16px;
}

@include assign-icon('icon-check', $pba-icon-check, 'lg') {
}

@include assign-icon('icon-check', $pba-icon-check, 'xl') {
}

@include assign-icon('icon-check', $pba-icon-check, 'sm');

@include assign-icon('icon-check-bg-blue', $pba-icon-check, 'lg', $black, $blue) {
}

@include assign-icon('icon-penalized', $pba-icon-penalized, 'lg') {
}

@include assign-icon('icon-table', $pba-icon-penalized) {
  font-size: 16px;
}

// pdf
@include assign-icon('icon-pdf', $pba-icon-pdf) {
  font-size: 16px;
}

@include assign-icon('icon-pdf', $pba-icon-pdf, 'lg') {
}

@include assign-icon('icon-pdf', $pba-icon-pdf, 'xl') {
}

@include assign-icon('icon-pdf', $pba-icon-pdf, 'sm');

@include assign-icon('icon-pdf-bg-blue', $pba-icon-pdf, 'lg', $black, $blue) {
}

// up
@include assign-icon('icon-up', $pba-icon-up) {
  font-size: 16px;
}

@include assign-icon('icon-up', $pba-icon-up, 'lg') {
}

@include assign-icon('icon-up', $pba-icon-up, 'xl') {
}

@include assign-icon('icon-up', $pba-icon-up, 'sm');

@include assign-icon('icon-up-bg-blue', $pba-icon-up, 'lg', $black, $blue) {
}

// redo
@include assign-icon('icon-redo', $pba-icon-redo) {
  font-size: 16px;
}

@include assign-icon('icon-redo', $pba-icon-redo, 'lg') {
  font-size: 16px;
}

@include assign-icon('icon-redo', $pba-icon-redo, 'xl') {
}

@include assign-icon('icon-redo', $pba-icon-redo, 'sm');

@include assign-icon('icon-redo-bg-blue', $pba-icon-redo, 'lg', $black, $blue) {
}

// earth
@include assign-icon('icon-earth', $pba-icon-earth) {
  font-size: 16px;
}

@include assign-icon('icon-earth', $pba-icon-earth, 'sp') {
}

@include assign-icon('icon-earth', $pba-icon-earth, 'lg') {
  font-size: 13px;
}

@include assign-icon('icon-earth', $pba-icon-earth, 'xl') {
}

@include assign-icon('icon-earth', $pba-icon-earth, 'sm');

@include assign-icon('icon-earth-bg-blue', $pba-icon-earth, 'lg', $black, $blue) {
}

// book
@include assign-icon('icon-book', $pba-icon-book) {
  font-size: 16px;
}

@include assign-icon('icon-book', $pba-icon-book, 'lg') {
  font-size: 13px;
}

@include assign-icon('icon-book', $pba-icon-book, 'xl') {
}

@include assign-icon('icon-book', $pba-icon-book, 'sm');

@include assign-icon('icon-book-bg-blue', $pba-icon-book, 'lg', $black, $blue) {
}

// sphere
@include assign-icon('icon-sphere', $pba-icon-sphere) {
  font-size: 16px;
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'lg') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'xl') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'sm');

@include assign-icon('icon-sphere-bg-blue', $pba-icon-sphere, 'lg', $black, $blue) {
}

// page-break
@include assign-icon('icon-page-break', $pba-icon-page-break) {
  font-size: 16px;
}

@include assign-icon('icon-page-break', $pba-icon-page-break, 'lg') {
  font-size: 14px;
}

@include assign-icon('icon-page-break', $pba-icon-page-break, 'xl') {
}

@include assign-icon('icon-page-break', $pba-icon-page-break, 'sm');

@include assign-icon('icon-page-break-bg-blue', $pba-icon-page-break, 'lg', $black, $blue) {
}

@include assign-icon(
  'icon-historic-blue',
  $pba-icon-historic,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-historic', $pba-icon-historic, 'md') {
}

@include assign-icon(
  'icon-receptor-blue',
  $pba-icon-receptor,
  'md',
  $axess-color-blue,
  $pba-table-action-background
) {
  font-size: 27px;
}

// icono podcast

@include assign-icon('icon-wifi', $pba-icon-wifi, 'md', $axess-color-blue, $pba-table-action-background) {
  font-size: 27px;
}


@include assign-icon('icon-unpenalized-edit,', $pba-icon-unpenalized, 'md') {
}
}


.nms{
  
@include assign-icon('icon-menu', $pba-icon-menu) {
  font-size: 16px;
}

@include assign-icon('icon-menu', $pba-icon-menu, 'lg') {
}

@include assign-icon('icon-menu', $pba-icon-menu, 'xl') {
}

@include assign-icon('icon-menu', $pba-icon-menu, 'sm');

@include assign-icon('icon-menu-bg-blue', $pba-icon-menu, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-satelite) {
  font-size: 16px;
}

@include assign-icon('icon-satelite', $pba-icon-satelite, 'lg') {
}

@include assign-icon('icon-satelite', $pba-icon-satelite, 'xl') {
}

@include assign-icon('icon-satelite', $pba-icon-satelite, 'sm');

@include assign-icon('icon-satelite-bg-blue', $pba-icon-satelite, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-sphere) {
  font-size: 16px;
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'lg') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'xl') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'sm');

@include assign-icon('icon-sphere-bg-blue', $pba-icon-sphere, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-direccion-ip) {
  font-size: 16px;
}

@include assign-icon('icon-direccion-ip', $pba-icon-direccion-ip, 'lg') {
}

@include assign-icon('icon-direccion-ip', $pba-icon-direccion-ip, 'xl') {
}

@include assign-icon('icon-direccion-ip', $pba-icon-direccion-ip, 'sm');

@include assign-icon('icon-direccion-ip-bg-blue', $pba-icon-direccion-ip, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-undo) {
  font-size: 16px;
}

@include assign-icon('icon-undo', $pba-icon-undo, 'lg') {
}

@include assign-icon('icon-undo', $pba-icon-undo, 'xl') {
}

@include assign-icon('icon-undo', $pba-icon-undo, 'sm');

@include assign-icon('icon-undo-bg-blue', $pba-icon-undo, 'lg', $black, $blue) {
}

@include assign-icon('icon-display', $pba-icon-display) {
  font-size: 16px;
}

@include assign-icon('icon-display', $pba-icon-display, 'lg') {
}

@include assign-icon('icon-display', $pba-icon-display, 'xl') {
}

@include assign-icon('icon-display', $pba-icon-display, 'sm');

@include assign-icon('icon-display-bg-blue', $pba-icon-display, 'lg', $black, $blue) {
}

@include assign-icon(
  'icon-unblocked-blue',
  $pba-icon-unblocked,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-menu', $pba-icon-eye) {
  font-size: 16px;
}

@include assign-icon('icon-eye', $pba-icon-eye, 'lg') {
}

@include assign-icon('icon-eye', $pba-icon-eye, 'xl') {
}

@include assign-icon('icon-eye', $pba-icon-eye, 'sm');

@include assign-icon('icon-eye-bg-blue', $pba-icon-eye, 'lg', $black, $blue) {
}

@include assign-icon('icon-menu', $pba-icon-meter) {
  font-size: 16px;
}

@include assign-icon('icon-meter', $pba-icon-meter, 'lg') {
}

@include assign-icon('icon-meter', $pba-icon-meter, 'xl') {
}

@include assign-icon('icon-meter', $pba-icon-meter, 'sm');

@include assign-icon('icon-meter-bg-blue', $pba-icon-meter, 'lg', $black, $blue) {
}

@include assign-icon('icon-meter-blue', $pba-icon-meter, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-receptor2', $pba-icon-receptor) {
  font-size: 16px;
}

@include assign-icon('icon-receptor2', $pba-icon-receptor, 'lg') {
  font-size: 29px;
}

@include assign-icon('icon-receptor2', $pba-icon-receptor, 'xl') {
}

@include assign-icon('icon-receptor2', $pba-icon-receptor, 'sm');

@include assign-icon('icon-receptor2-bg-blue', $pba-icon-receptor, 'lg', $black, $blue) {
}

@include assign-icon(
  'icon-receptor2-blue',
  $pba-icon-receptor,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-menu', $pba-icon-tab) {
  font-size: 16px;
}

@include assign-icon('icon-tab', $pba-icon-tab, 'lg') {
}

@include assign-icon('icon-tab', $pba-icon-tab, 'xl') {
}

@include assign-icon('icon-tab', $pba-icon-tab, 'sm');

@include assign-icon('icon-tab-bg-blue', $pba-icon-tab, 'lg', $black, $blue) {
}

// onOFF
@include assign-icon('icon-sign-off', $pba-icon-sign-off) {
  font-size: 16px;
}

@include assign-icon('icon-sign-off', $pba-icon-image, 'lg') {
}

@include assign-icon('icon-sign-off', $pba-icon-sign-off, 'xl') {
}

@include assign-icon('icon-sign-off', $pba-icon-sign-off, 'sm');

@include assign-icon('icon-edit-table', $pba-icon-edit, 'lg');

@include assign-icon('icon-sign-off-bg-blue', $pba-icon-sign-off, 'lg', $black, $blue) {
}

//Icono imagen

@include assign-icon('icon-image', $pba-icon-image) {
  font-size: 16px;
}

@include assign-icon('icon-image', $pba-icon-image, 'lg') {
}

@include assign-icon('icon-image', $pba-icon-image, 'xl') {
}

@include assign-icon('icon-image', $pba-icon-image, 'sm');

@include assign-icon('icon-image-bg-blue', $pba-icon-image, 'lg', $black, $blue) {
}

//Icono flecha

@include assign-icon('icon-down', $pba-icon-down) {
  font-size: 16px;
}

@include assign-icon('icon-down', $pba-icon-down, 'lg') {
}

@include assign-icon('icon-down', $pba-icon-down, 'xl') {
}

@include assign-icon('icon-down', $pba-icon-down, 'sm');

@include assign-icon('icon-down-bg-blue', $pba-icon-down, 'lg', $black, $blue) {
}

//Icono help

@include assign-icon('icon-help', $pba-icon-help) {
  font-size: 16px;
}

@include assign-icon('icon-help', $pba-icon-help, 'lg') {
}

@include assign-icon('icon-help', $pba-icon-help, 'xl') {
}

@include assign-icon('icon-help', $pba-icon-help, 'sm');

@include assign-icon('icon-help-bg-blue', $pba-icon-help, 'lg', $black, $blue) {
}

//icono soporte

@include assign-icon('icon-support', $pba-icon-support) {
  font-size: 16px;
}

@include assign-icon('icon-support', $pba-icon-support, 'lg') {
}

@include assign-icon('icon-support', $pba-icon-support, 'xl') {
}

@include assign-icon('icon-support', $pba-icon-support, 'sm');

@include assign-icon('icon-support-bg-blue', $pba-icon-support, 'lg', $black, $blue) {
}

//icono connection

@include assign-icon('icon-connection', $pba-icon-connection) {
  font-size: 16px;
}

@include assign-icon('icon-connection', $pba-icon-connection, 'lg') {
  font-size: 16px;
  margin-right: 4px;
}

@include assign-icon('icon-connection', $pba-icon-connection, 'xl') {
}

@include assign-icon('icon-connection', $pba-icon-connection, 'sm');

@include assign-icon('icon-connection-bg-blue', $pba-icon-connection, 'lg', $black, $blue) {
  font-size: 16px;
}

//icono wrench

@include assign-icon('icon-wrench', $pba-icon-wrench) {
  font-size: 16px;
}

@include assign-icon('icon-wrench', $pba-icon-wrench, 'lg') {
  font-size: 16px;
  margin-right: 8px;
}

@include assign-icon('icon-wrench', $pba-icon-wrench, 'xl') {
}

@include assign-icon('icon-wrench', $pba-icon-wrench, 'sm');

@include assign-icon('icon-wrench-bg-blue', $pba-icon-wrench, 'lg', $black, $blue) {
  font-size: 16px;
}

//icono history ip

@include assign-icon('icon-history-ip', $pba-icon-history-ip) {
  font-size: 16px;
}

@include assign-icon('icon-history-ip', $pba-icon-history-ip, 'lg') {
  font-size: 16px;
  margin-right: 8px;
}

@include assign-icon('icon-history-ip', $pba-icon-history-ip, 'xl') {
}

@include assign-icon('icon-history-ip', $pba-icon-history-ip, 'sm');

@include assign-icon('icon-history-ip-bg-blue', $pba-icon-history-ip, 'lg', $black, $blue) {
  font-size: 16px;
}

//price-tags

@include assign-icon('icon-price-tags', $pba-icon-price-tags) {
  font-size: 16px;
}

@include assign-icon('icon-price-tags', $pba-icon-price-tags, 'lg') {
  font-size: 16px;
  margin-right: 3px;
}

@include assign-icon('icon-price-tags', $pba-icon-price-tags, 'xl') {
}

@include assign-icon('icon-price-tags', $pba-icon-price-tags, 'sm');

@include assign-icon('icon-price-tags-bg-blue', $pba-icon-price-tags, 'lg', $black, $blue) {
  font-size: 16px;
}
//Icono usuario

@include assign-icon('icon-user', $pba-icon-user) {
  font-size: 16px;
}

@include assign-icon('icon-user', $pba-icon-user, 'lg') {
}

@include assign-icon('icon-user', $pba-icon-user, 'xl') {
}

@include assign-icon('icon-user', $pba-icon-user, 'sm');

@include assign-icon('icon-user-bg-blue', $pba-icon-user, 'lg', $black, $blue) {
}

//Icono router

@include assign-icon('icon-router', $pba-icon-router) {
  font-size: 16px;
}

@include assign-icon('icon-router', $pba-icon-router, 'lg') {
}

@include assign-icon('icon-router', $pba-icon-router, 'xl') {
}

@include assign-icon('icon-router', $pba-icon-router, 'sm');

@include assign-icon('icon-router-bg-blue', $pba-icon-router, 'lg', $black, $blue) {
}

//Icono router con señal

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi) {
  font-size: 16px;
}

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi, 'lg') {
}

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi, 'xl') {
}

@include assign-icon('icon-router-wifi', $pba-icon-router-wifi, 'sm');

@include assign-icon('icon-router-wifi-bg-blue', $pba-icon-router-wifi, 'lg', $black, $blue) {
}

//Icono change platform

@include assign-icon('icon-change-platform', $pba-icon-change-platform) {
  font-size: 16px;
}

@include assign-icon('icon-change-platform', $pba-icon-change-platform, 'lg') {
}

@include assign-icon('icon-change-platform', $pba-icon-change-platform, 'xl') {
}

@include assign-icon('icon-change-platform', $pba-icon-change-platform, 'sm');

@include assign-icon('icon-change-platform-bg-blue', $pba-icon-change-platform, 'lg', $black, $blue) {
}

//Icono parabolica

@include assign-icon('icon-parabolica', $pba-icon-parabolica) {
  font-size: 16px;
}

@include assign-icon('icon-parabolica', $pba-icon-parabolica, 'lg') {
}

@include assign-icon('icon-parabolica', $pba-icon-parabolica, 'xl') {
}

@include assign-icon('icon-parabolica', $pba-icon-parabolica, 'sm');

@include assign-icon('icon-parabolica-bg-blue', $pba-icon-parabolica, 'lg', $black, $blue) {
}

//Icono antena

@include assign-icon('icon-antena', $pba-icon-antena) {
  font-size: 16px;
}

@include assign-icon('icon-antena', $pba-icon-antena, 'lg') {
}

@include assign-icon('icon-antena', $pba-icon-antena, 'xl') {
}

@include assign-icon('icon-antena', $pba-icon-antena, 'sm');

@include assign-icon('icon-antena-bg-blue', $pba-icon-antena, 'lg', $black, $blue) {
}

//Logo

@include assign-icon('icon-logo', $pba-icon-logo) {
  font-size: 16px;
}

@include assign-icon('icon-logo', $pba-icon-logo, 'lg') {
}

@include assign-icon('icon-logo', $pba-icon-logo, 'xl') {
}

@include assign-icon('icon-logo', $pba-icon-logo, 'sm');

@include assign-icon('icon-logo-bg-blue', $pba-icon-logo, 'lg', $black, $blue) {
}

// Candado

@include assign-icon('icon-candado', $pba-icon-candado) {
  font-size: 16px;
}

@include assign-icon('icon-candado', $pba-icon-candado, 'lg') {
}

@include assign-icon('icon-candado', $pba-icon-candado, 'xl') {
}

@include assign-icon('icon-candado', $pba-icon-candado, 'sm');

@include assign-icon('icon-candado-bg-blue', $pba-icon-candado, 'lg', $black, $blue) {
}

//logo ok

@include assign-icon('icon-logok', $pba-icon-logok) {
  font-size: 16px;
}

@include assign-icon('icon-logok', $pba-icon-logok, 'lg') {
}

@include assign-icon('icon-logok', $pba-icon-logok, 'xl') {
}

@include assign-icon('icon-logok', $pba-icon-logok, 'sm');

@include assign-icon('icon-logok-bg-blue', $pba-icon-logok, 'lg', $black, $blue) {
}

//Calendario

@include assign-icon('icon-calendar', $pba-icon-calendar) {
  font-size: 16px;
}

@include assign-icon('icon-calendar', $pba-icon-calendar, 'lg') {
}

@include assign-icon('icon-calendar', $pba-icon-calendar, 'xl') {
}

@include assign-icon('icon-calendar', $pba-icon-calendar, 'sm');

@include assign-icon('icon-calendar-bg-blue', $pba-icon-calendar, 'lg', $black, $blue) {
}

// Table Icons
@include assign-icon(
  'icon-delete-blue',
  $pba-icon-delete,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-alert-blue',
  $pba-icon-alert,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-delete-blue',
  $pba-icon-delete,
  'sm',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 22px;
}

// Table Icons
@include assign-icon('icon-clock-blue', $pba-icon-clock, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-check-blue', $pba-icon-check, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon(
  'icon-sphere-blue',
  $pba-icon-sphere,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-direccion-ip-blue',
  $pba-icon-direccion-ip,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

// // todo
// @include assign-icon(
//   'icon-direccion-ip-blue',
//   $pba-icon-direccion-ip,
//   'md',
//   $pba-table-action-color,
//   $pba-table-action-background
// ) {
//   font-size: 27px;
// }

@include assign-icon('icon-undo-blue', $pba-icon-undo, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-eye-blue', $pba-icon-eye, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}



@include assign-icon('provisional-delete-blue', $pba-icon-temporal-delete, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}


@include assign-icon('perma-delete-blue', $pba-icon-perma-delete, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('change-client-blue', $pba-icon-user, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}



@include assign-icon('icon-qts-blue', $pba-icon-cogs, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-serial-number-blue', $pba-icon-cogs, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-tab-blue', $pba-icon-tab, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon(
  'icon-sign-off-blue',
  $pba-icon-sign-off,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-wifi-off-blue',
  $pba-icon-wifi,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-force-activation-blue',
  $pba-icon-force-activation,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}


@include assign-icon(
  'icon-provisional-delete-blue',
  $pba-icon-temporal-delete,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}


@include assign-icon(
  'icon-perma-delete-blue',
  $pba-icon-perma-delete,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}


@include assign-icon(
  'icon-change-client-blue',
  $pba-icon-user,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}


@include assign-icon(
  'icon-change-platform-blue',
  $pba-icon-change-platform,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-assignment',
  $pba-icon-assignment,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 30px;
}

@include assign-icon(
  'icon-sign-off-red',
  $pba-icon-sign-off,
  'md',
  $pba-table-state-ko-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

@include assign-icon(
  'icon-wifi-off-red',
  $pba-icon-wifi,
  'md',
  $pba-table-state-ko-color,
  $pba-table-action-background
) {
  font-size: 20px;
}

// @include assign-icon('icon-confirm-blue', $pba-icon-confirm, 'md', $pba-table-action-color, $pba-table-action-background) {
//   font-size: 27px;
// }

@include assign-icon(
  'icon-router-wifi-blue',
  $pba-icon-router-wifi,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-change-platform-blue',
  $pba-icon-change-platform,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-terminal-wifi', $pba-icon-terminal-wifi, 'lg') {
  color: #307fe2;
  background: #fff;
  font-size: 34px;
}

@include assign-icon('icon-edit-blue', $pba-icon-edit, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon('icon-edit-blue-table', $pba-icon-edit) {
  font-size: 16px;
}

@include assign-icon('icon-qts-blue', $pba-icon-qts, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}

@include assign-icon(
  'icon-times-outline-blue',
  $pba-icon-times-outline,
  'sm',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 14px;
  padding: 4px;
}

@include assign-icon(
  'icon-monitoring-blue',
  $pba-icon-monitoring,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon(
  'icon-share-blue',
  $pba-icon-share-little,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon(
  'icon-search-blue',
  $pba-icon-search,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon('icon-play2-blue', $pba-icon-play2, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
  opacity: 0.9;
}
@include assign-icon(
  'icon-forward-blue',
  $pba-icon-forward,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon(
  'icon-download-blue',
  $pba-icon-download,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon(
  'icon-config_colums-blue',
  $pba-icon-config_colums,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
  opacity: 0.9;
}

@include assign-icon('icon-plus-blue', $pba-icon-plus, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 15px;
  opacity: 0.9;
}

@include assign-icon('icon-map-blue', $pba-icon-map, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 15px;
  opacity: 0.9;
}

//Info

@include assign-icon('icon-info', $pba-icon-info) {
  font-size: 16px;
}

@include assign-icon('icon-info', $pba-icon-info, 'lg') {
}

@include assign-icon('icon-info', $pba-icon-info, 'xl') {
}

@include assign-icon('icon-info', $pba-icon-info, 'sm');

@include assign-icon('icon-info-bg-blue', $pba-icon-info, 'lg', $black, $blue) {
}

// Danger

@include assign-icon('icon-danger', $pba-icon-danger) {
  font-size: 16px;
}

@include assign-icon('icon-danger', $pba-icon-danger, 'lg') {
}

@include assign-icon('icon-danger', $pba-icon-danger, 'xl') {
}

@include assign-icon('icon-danger', $pba-icon-danger, 'sm');

@include assign-icon('icon-danger-bg-blue', $pba-icon-danger, 'lg', $black, $blue) {
}

//Users

@include assign-icon('icon-users', $pba-icon-users) {
  font-size: 16px;
}

@include assign-icon('icon-users', $pba-icon-users, 'lg') {
}

@include assign-icon('icon-users', $pba-icon-users, 'xl') {
}

@include assign-icon('icon-users', $pba-icon-users, 'sm');

@include assign-icon('icon-users-bg-blue', $pba-icon-users, 'lg', $black, $blue) {
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked) {
  font-size: 16px;
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked, 'lg') {
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked, 'xl') {
}

@include assign-icon('icon-unblocked', $pba-icon-unblocked, 'sm');

@include assign-icon('icon-unblocked-bg-blue', $pba-icon-unblocked, 'lg', $black, $blue) {
}

// chart

@include assign-icon('icon-chart', $pba-icon-chart) {
  font-size: 16px;
}

@include assign-icon('icon-chart', $pba-icon-chart, 'lg') {
}

@include assign-icon('icon-chart', $pba-icon-chart, 'xl') {
}

@include assign-icon('icon-chart', $pba-icon-chart, 'sm');

@include assign-icon('icon-chart-bg-blue', $pba-icon-chart, 'lg', $black, $blue) {
}

// receptor

@include assign-icon('icon-receptor', $pba-icon-receptor) {
  font-size: 16px;
}

@include assign-icon('icon-receptor', $pba-icon-receptor, 'lg') {
}

@include assign-icon('icon-receptor', $pba-icon-receptor, 'sm');

@include assign-icon('icon-receptor-bg-blue', $pba-icon-receptor, 'lg', $black, $blue) {
}

@include assign-icon('icon-receptor', $pba-icon-receptor, 'xl') {
}
// file-text2

@include assign-icon('icon-file-text2', $pba-icon-file-text2) {
  font-size: 16px;
}

@include assign-icon('icon-file-text2', $pba-icon-file-text2, 'lg') {
}

@include assign-icon('icon-file-text2', $pba-icon-file-text2, 'sm');

@include assign-icon('icon-file-text2-bg-blue', $pba-icon-file-text2, 'lg', $black, $blue) {
}

// stack

@include assign-icon('icon-stack', $pba-icon-stack) {
  font-size: 16px;
}

@include assign-icon('icon-stack', $pba-icon-stack, 'lg') {
}

@include assign-icon('icon-stack', $pba-icon-stack, 'sm');

@include assign-icon('icon-stack', $pba-icon-stack, 'xl');

@include assign-icon('icon-stack-bg-blue', $pba-icon-stack, 'lg', $black, $blue) {
}

// left arrow

@include assign-icon('icon-map', $pba-icon-map) {
  font-size: 16px;
}

@include assign-icon('icon-map', $pba-icon-map, 'lg') {
}

@include assign-icon('icon-map', $pba-icon-map, 'xl') {
}

@include assign-icon('icon-map', $pba-icon-map, 'sm');

@include assign-icon('icon-map-bg-blue', $pba-icon-map, 'lg', $black, $blue) {
}

// left arrow

@include assign-icon('icon-map2', $pba-icon-map2) {
  font-size: 16px;
}

@include assign-icon('icon-map2', $pba-icon-map2, 'lg') {
}

@include assign-icon('icon-map2', $pba-icon-map2, 'xl') {
}

@include assign-icon('icon-map2', $pba-icon-map2, 'sm');

@include assign-icon('icon-map2-bg-blue', $pba-icon-map2, 'lg', $black, $blue) {
}

// barra para listas en terminales
@include assign-icon('icon-rows', $pba-icon-rows) {
  font-size: 16px;
}

@include assign-icon('icon-rows', $pba-icon-rows, 'lg') {
}

@include assign-icon('icon-rows', $pba-icon-rows, 'xl') {
}

@include assign-icon('icon-rows', $pba-icon-rows, 'sm');

@include assign-icon('icon-rows-bg-blue', $pba-icon-rows, 'lg', $black, $blue) {
}

// simbolo de añadir

@include assign-icon('icon-add', $pba-icon-add) {
  font-size: 16px;
}

@include assign-icon('icon-add', $pba-icon-add, 'lg') {
}

@include assign-icon('icon-add', $pba-icon-add, 'xl') {
}

@include assign-icon('icon-add', $pba-icon-add, 'sm');

@include assign-icon('icon-add-bg-blue', $pba-icon-add, 'lg', $black, $blue) {
}

@include assign-icon('icon-add', $pba-icon-add, 'xxxl') {
}

// reset

@include assign-icon('icon-reset', $pba-icon-reset) {
  font-size: 16px;
}

@include assign-icon('icon-reset', $pba-icon-reset, 'lg') {
}

@include assign-icon('icon-reset', $pba-icon-reset, 'xl') {
}

@include assign-icon('icon-reset', $pba-icon-reset, 'sm');

@include assign-icon('icon-reset-bg-blue', $pba-icon-reset, 'lg', $black, $blue) {
}

// Danger
// @include assign-icon('icon-dangers', $pba-icon-dangers) {
//   font-size: 16px;
// }

// @include assign-icon('icon-dangers', $pba-icon-dangers, "lg") {}

// @include assign-icon('icon-dangers', $pba-icon-dangers, "xl") {}

// @include assign-icon('icon-dangers', $pba-icon-dangers, "sm");

// @include assign-icon('icon-dangers-bg-blue', $pba-icon-dangers, "lg", $black, $blue) {}

// rectangulo danger

@include assign-icon('icon-dangers', $pba-icon-dangers) {
  font-size: 16px;
}

@include assign-icon('icon-dangers', $pba-icon-dangers, 'lg') {
}

@include assign-icon('icon-dangers', $pba-icon-dangers, 'xl') {
}

@include assign-icon('icon-dangers', $pba-icon-dangers, 'sm');

@include assign-icon('icon-dangers-bg-blue', $pba-icon-dangers, 'lg', $black, $blue) {
}

// icono download
@include assign-icon('icon-download', $pba-icon-download) {
  font-size: 16px;
}

@include assign-icon('icon-download', $pba-icon-download, 'lg') {
}

@include assign-icon('icon-download', $pba-icon-download, 'xl') {
}

@include assign-icon('icon-download', $pba-icon-download, 'sm');

@include assign-icon('icon-download-bg-blue', $pba-icon-download, 'lg', $black, $blue) {
}

// icono forward
@include assign-icon('icon-forward', $pba-icon-download) {
  font-size: 16px;
}

// icono config colum
@include assign-icon('icon-config_colums', $pba-icon-config_colums) {
  font-size: 16px;
}

@include assign-icon('icon-config_colums', $pba-icon-config_colums, 'lg') {
}

@include assign-icon('icon-config_colums', $pba-icon-config_colums, 'xl') {
}

@include assign-icon('icon-config_colums', $pba-icon-config_colums, 'sm');

@include assign-icon('icon-config_colums-bg-blue', $pba-icon-config_colums, 'lg', $black, $blue) {
}

// icono migrate unico
@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico) {
  font-size: 16px;
}

@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico, 'lg') {
}

@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico, 'xl') {
}

@include assign-icon('icon-migrate-unico', $pba-icon-migrate-unico, 'sm');

@include assign-icon('icon-migrate-unico-bg-blue', $pba-icon-migrate-unico, 'lg', $black, $blue) {
}

// icono migrate vths
@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts) {
  font-size: 16px;
}

@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts, 'lg') {
}

@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts, 'xl') {
}

@include assign-icon('icon-migrate-vhts', $pba-icon-migrate-vhts, 'sm');

@include assign-icon('icon-migrate-vhts-bg-blue', $pba-icon-migrate-vhts, 'lg', $black, $blue) {
}

// icono plus
@include assign-icon('icon-plus', $pba-icon-plus) {
  font-size: 16px;
}

@include assign-icon('icon-plus', $pba-icon-plus, 'lg') {
}

@include assign-icon('icon-plus', $pba-icon-plus, 'xl') {
}

@include assign-icon('icon-plus', $pba-icon-plus, 'sm');

@include assign-icon('icon-plus-bg-blue', $pba-icon-plus, 'lg', $black, $blue) {
  font-size: 16px;
}

@include assign-icon('icon-forward', $pba-icon-forward, 'lg') {
}

@include assign-icon('icon-forward', $pba-icon-forward, 'xl') {
}

@include assign-icon('icon-forward', $pba-icon-forward, 'sm');

@include assign-icon('icon-forward-bg-blue', $pba-icon-forward, 'lg', $black, $blue) {
}

// icono circulo
@include assign-icon('icon-uniE900', $pba-icon-uniE900) {
  font-size: 16px;
}

@include assign-icon('icon-uniE900', $pba-icon-uniE900, 'lg') {
}

@include assign-icon('icon-uniE900', $pba-icon-uniE900, 'xl') {
}

@include assign-icon('icon-uniE900', $pba-icon-uniE900, 'sm');

@include assign-icon('icon-uniE900-bg-red', $pba-icon-uniE900, 'lg', $red, $red) {
}

// icono upload
@include assign-icon('icon-upload', $pba-icon-upload) {
  font-size: 16px;
}

@include assign-icon('icon-upload', $pba-icon-upload, 'lg') {
}

@include assign-icon('icon-upload', $pba-icon-upload, 'xl') {
}

@include assign-icon('icon-delete', $pba-icon-delete, 'xl') {
}

@include assign-icon('icon-delete', $pba-icon-delete, 'lg') {
}

@include assign-icon('icon-upload', $pba-icon-upload, 'sm');

@include assign-icon('icon-upload-bg-blue', $pba-icon-upload, 'lg', $black, $blue) {
}

// icono test

@include assign-icon('icon-test', $pba-icon-test) {
  font-size: 16px;
}

@include assign-icon('icon-test', $pba-icon-test, 'lg') {
}

@include assign-icon('icon-test', $pba-icon-test, 'xl') {
}

@include assign-icon('icon-test', $pba-icon-test, 'sm');

@include assign-icon('icon-test-bg-blue', $pba-icon-test, 'lg', $black, $blue) {
}

//Check

@include assign-icon('icon-check', $pba-icon-check) {
  font-size: 16px;
}

@include assign-icon('icon-check', $pba-icon-check, 'lg') {
}

@include assign-icon('icon-check', $pba-icon-check, 'xl') {
}

@include assign-icon('icon-check', $pba-icon-check, 'sm');

@include assign-icon('icon-check-bg-blue', $pba-icon-check, 'lg', $black, $blue) {
}

@include assign-icon('icon-penalized', $pba-icon-penalized, 'lg') {
}

@include assign-icon('icon-table', $pba-icon-penalized) {
  font-size: 16px;
}

// pdf
@include assign-icon('icon-pdf', $pba-icon-pdf) {
  font-size: 16px;
}

@include assign-icon('icon-pdf', $pba-icon-pdf, 'lg') {
}

@include assign-icon('icon-pdf', $pba-icon-pdf, 'xl') {
}

@include assign-icon('icon-pdf', $pba-icon-pdf, 'sm');

@include assign-icon('icon-pdf-bg-blue', $pba-icon-pdf, 'lg', $black, $blue) {
}

// up
@include assign-icon('icon-up', $pba-icon-up) {
  font-size: 16px;
}

@include assign-icon('icon-up', $pba-icon-up, 'lg') {
}

@include assign-icon('icon-up', $pba-icon-up, 'xl') {
}

@include assign-icon('icon-up', $pba-icon-up, 'sm');

@include assign-icon('icon-up-bg-blue', $pba-icon-up, 'lg', $black, $blue) {
}

// redo
@include assign-icon('icon-redo', $pba-icon-redo) {
  font-size: 16px;
}

@include assign-icon('icon-redo', $pba-icon-redo, 'lg') {
  font-size: 16px;
}

@include assign-icon('icon-redo', $pba-icon-redo, 'xl') {
}

@include assign-icon('icon-redo', $pba-icon-redo, 'sm');

@include assign-icon('icon-redo-bg-blue', $pba-icon-redo, 'lg', $black, $blue) {
}

// earth
@include assign-icon('icon-earth', $pba-icon-earth) {
  font-size: 16px;
}

@include assign-icon('icon-earth', $pba-icon-earth, 'sp') {
}

@include assign-icon('icon-earth', $pba-icon-earth, 'lg') {
  font-size: 13px;
}

@include assign-icon('icon-earth', $pba-icon-earth, 'xl') {
}

@include assign-icon('icon-earth', $pba-icon-earth, 'sm');

@include assign-icon('icon-earth-bg-blue', $pba-icon-earth, 'lg', $black, $blue) {
}

// book
@include assign-icon('icon-book', $pba-icon-book) {
  font-size: 16px;
}

@include assign-icon('icon-book', $pba-icon-book, 'lg') {
  font-size: 13px;
}

@include assign-icon('icon-book', $pba-icon-book, 'xl') {
}

@include assign-icon('icon-book', $pba-icon-book, 'sm');

@include assign-icon('icon-book-bg-blue', $pba-icon-book, 'lg', $black, $blue) {
}

// sphere
@include assign-icon('icon-sphere', $pba-icon-sphere) {
  font-size: 16px;
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'lg') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'xl') {
}

@include assign-icon('icon-sphere', $pba-icon-sphere, 'sm');

@include assign-icon('icon-sphere-bg-blue', $pba-icon-sphere, 'lg', $black, $blue) {
}

// page-break
@include assign-icon('icon-page-break', $pba-icon-page-break) {
  font-size: 16px;
}

@include assign-icon('icon-page-break', $pba-icon-page-break, 'lg') {
  font-size: 14px;
}

@include assign-icon('icon-page-break', $pba-icon-page-break, 'xl') {
}

@include assign-icon('icon-page-break', $pba-icon-page-break, 'sm');

@include assign-icon('icon-page-break-bg-blue', $pba-icon-page-break, 'lg', $black, $blue) {
}

@include assign-icon(
  'icon-historic-blue',
  $pba-icon-historic,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

@include assign-icon('icon-historic', $pba-icon-historic, 'md') {
}

@include assign-icon(
  'icon-receptor-blue',
  $pba-icon-receptor,
  'md',
  $pba-table-action-color,
  $pba-table-action-background
) {
  font-size: 27px;
}

// icono podcast

@include assign-icon('icon-wifi', $pba-icon-wifi, 'md', $pba-table-action-color, $pba-table-action-background) {
  font-size: 27px;
}


@include assign-icon('icon-unpenalized-edit,', $pba-icon-unpenalized, 'md') {
}
}
