@include assign-icon('icon-clock', $pba-icon-clock, 'lg') {
  font-size: 13px;
  opacity: 0.9;
}

.pba-graph-select2 {
  position: relative;
  bottom: 1px;

  @extend .pba-input-text;

  .select2-selection--single {
    // background-color: red;
    height: 34px;
    border: 1px solid #cedacf;
    display: flex;
    align-items: center;

    .select2-selection__arrow {
      top: 3px;
    }
  }

  // .select2-results__option li {
  //   font-size: 14px;
  //   background-color: blue;
  // }

  // &.icon-reset-lg::before {
  //   position: absolute;
  // }
  &.icon-clock-lg:before {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
  }

  .select2-selection {
    padding-left: 21px;
  }
}

.pba-select2 {
  .select2-container {
    .select2-selection--single {
      height: 47px !important;
      border: 1px solid #ced4da;
      padding-left: 8px;
    }

    span {
      color: #4e5b73 !important;
      font-size: 14px;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px !important;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px;
  }

  .select2-container--disabled {
    .select2-selection {
      background-color: #d7dce5;
    }
  }
}
