.pba-dropdown {
  .pba-dropdown-button {
    @extend .pba-input-text;
    @extend .dropdown-toggle;
  }
}

@mixin input--little {
  width: 200px;
  align-items: center;
  display: flex;
}

app-dropdown {
  &.pba-dropdown--little {
    @include input--little;
  }

  &.is-invalid {
    button {
      margin: 0;
      border: solid 1px $pba-error-border-color;
      background-image: none;
    }
  }

  &.pba-dropdown--without-dot {
    .b-dropdown {
      text-overflow: initial !important;
      padding-right: 21px;
    }
  }
}
