.pba-title-card {
  padding: 24px;
  font-size: 37px;
  color: $pba-color-head;
}

.card {
  margin: 0 24px;

  .card-body {
    padding: 1.25rem;
  }
}
