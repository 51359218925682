.header {
  height: 88px;
  border-bottom: $pba-header;

  .migas {
    padding: 24px;
    font-size: 90%;
    font-weight: 600;
    color: black;
  }

  .cabecera {
    width: 100%;
    margin-top: 8px;
    height: 48px;
    border-bottom: 1.5px solid rgba(227, 226, 226, 1);

    .icon-color .path1:before {
      content: '\e90c';
      color: #4e5b73;
    }

    .margenes {
      font-size: 32px;
      padding: 24px;
    }

    .icon-help-lg:before {
      font-size: 48px;
      // float: right;
      color: darkgray;
    }

    .icon-display-lg:before {
      font-size: 30px;
      // float: right;
      margin-top: 5px;
      margin-left: 7px;
    }

    .icon-display-red {
      color: red;
    }

    .icon-display-grey {
      color: darkgray;
    }

    .icon-color .path2:before {
      content: '\e90e';
      color: #307fe2;
      margin-left: -3.3896484375em;
    }

    .icon-notifications {
      .path1:before {
        font-size: 48px;
        // float: right;
        content: '\e919';
        color: darkgrey;
      }

      .path2:before {
        content: '\e91a';
        color: red;
        font-size: 48px;
        margin-left: -1em;
      }
    }

    .icon-notification {
      .path1:before {
        font-size: 48px;
        // float: right;
        content: '\e919';
        color: darkgrey;
      }

      .path2:before {
        content: '\e91a';
        color: white;
        font-size: 48px;
        margin-left: -1em;
      }
    }
    .icon-display-red {
      color: red !important;
    }

    .icon-hispasat {
      padding: 0 24px;
    }
  }

  .espacio {
    margin-top: 8px;
  }
}
