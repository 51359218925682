.pba-radio-button {
  width: 20px;
  height: 24px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #4e5b73;
}
