$pba-icon-satelite: '\e902';
$pba-icon-ellipsis: '\e90x';
$pba-icon-menu: '\e903';
$pba-icon-image: '\e90d';
$pba-icon-down: '\e904';
$pba-icon-help: '\e905';
$pba-icon-support: '\e906';
$pba-icon-user: '\e907';
$pba-icon-router: '\e908';
$pba-icon-router-wifi: '\e909';
$pba-icon-parabolica: '\e90a';
$pba-icon-antena: '\e90b';
$pba-icon-logo: '\e90c';
$pba-icon-candado: '\e90f';
$pba-icon-logok: '\e910';
$pba-icon-calendar: '\e911';
$pba-icon-bars: '\e912';
$pba-icon-delete: '\e913';
$pba-icon-edit: '\e914';
$pba-icon-info: '\e915';
$pba-icon-danger: '\e917';
$pba-icon-users: '\e91b';
$pba-icon-chart: '\e91d';
$pba-icon-receptor: '\e91c';
$pba-icon-monitoring: '\e922';
$pba-icon-up-arrow: '\e921';
$pba-icon-down-arrow: '\e91e';
$pba-icon-semi-right-arrow: '\e924';
$pba-icon-semi-left-arrow: '\e923';
$pba-icon-dangers: '\e925';
$pba-icon-sign-off: '\e926';
$pba-icon-map: '\e927';
$pba-icon-rows: '\e928';
$pba-icon-add: '\e929';
$pba-icon-reset: '\e92a';
$pba-icon-download: '\e92b';
$pba-icon-upload: '\e92c';
$pba-icon-test: '\e92d';
$pba-icon-check: '\e92e';
$pba-icon-pdf: '\e92f';
$pba-icon-zip: '\e930';
$pba-icon-xls: '\e92f';
$pba-icon-doc: '\e92f';
$pba-icon-bin: '\e92f';
$pba-icon-exe: '\e92f';
$pba-icon-msi: '\e92f';
$pba-icon-up: '\e931';
$pba-icon-redo: '\e932';
$pba-icon-book: '\e934';
$pba-icon-sphere: '\e935';
$pba-icon-page-break: '\e936';
$pba-icon-cloud-up-down: '\e938';
$pba-icon-house-wifi: '\e939';
$pba-icon-list-one: '\e93a';
$pba-icon-list: '\e93b';
$pba-icon-browser: '\e937';
// $pba-icon-earth:"\e933";
$pba-icon-earth: '\e940';
$pba-icon-satelite-off: '\e93c';
$pba-icon-satelite-on: '\e93d';
$pba-icon-tree: '\e93e';
$pba-icon-clock: '\e93f';
$pba-icon-share: '\e941';
$pba-icon-share-little: '\e942';
$pba-icon-cogs: '\e943';
$pba-icon-coin-dollar: '\e944';
$pba-icon-historic: '\e945';
$pba-icon-satelite-edit: '\e946';
$pba-icon-feed: '\e947';
$pba-icon-assignment: '\e973';

$pba-icon-file-text2: '\e948';
$pba-icon-stack: '\e949';
$pba-icon-forward: '\e969';
$pba-icon-search: '\e986';
$pba-icon-sphere: '\e9c9';
$pba-icon-direccion-ip: '\e94a';
$pba-icon-undo: '\e998';
$pba-icon-eye: '\e9ce';
$pba-icon-tab: '\ea45';
$pba-icon-uniE900: '\e901';
$pba-icon-display: '\e99b';

$pba-icon-unpenalized: '\ea10';
$pba-icon-penalized: '\ea0f';

$pba-icon-unblocked: '\e990';
$pba-icon-meter: '\e9a6';
$pba-icon-config_colums: '\e992';
$pba-icon-play2: '\ea15';

$pba-icon-connection: '\e94b';
$pba-icon-wrench: '\e991';

$pba-icon-plus: '\ea0a';

$pba-icon-icono-freezone: '\e94c';

$pba-icon-price-tags: '\e94d';

$pba-icon-icono-sc: '\e94e';

$pba-icon-wifi: '\e94f';

$pba-icon-force-activation: '\e995';

$pba-icon-map2: '\e900';

$pba-icon-terminal-wifi: '\e951';

$pba-icon-migrate-unico: '\ea2d';

$pba-icon-change-platform: '\e952';

$pba-icon-history-ip: '\e953';

$pba-icon-times-outline: '\e954';

$pba-icon-migrate-vhts: '\e98a';

$pba-icon-qts: '\e994';

$pba-icon-star-outline: '\e955';

$pba-icon-temporal-delete: '\e956';

$pba-icon-perma-delete: '\e9ad';

$pba-icon-alert: '\e957';
$pba-icon-login: '\e958';
$pba-icon-appmonitoring: '\e95e';
$pba-icon-bell: '\e959';
$pba-icon-help-circle: '\e95d';
$pba-icon-vision: '\e95f';
$pba-icon-hispasat: '\e95a';

$pba-icon-arrow-right: '\e960';
$pba-icon-arrow-left: '\e961';

$pba-icon-arrow-bread: '\e962';

$pba-icon-arrow-right-bread: '\e963';
