.pba-title-form {
  display: inline-block;
  margin-bottom: 0.5rem;
  border-bottom: $pba-disabled-color solid 1px;
  width: 100%;
}

.pba-title-form-picker {
  //   display: inline-block;
  //   margin-bottom: 0.5rem;
  //   border-bottom: $pba-disabled-color solid 1px;
  // width: 100%;
  width: 196px;
  // margin-left: 16px;
}

.pba-graph__options {
  border-bottom: $pba-disabled-color solid 1px;
  margin: 0 16px;
}

.pba-graph-timezone {
  width: 200px;
  display: flex;
  align-items: center;
}

.pba-title-form-modal {
  margin-top: 10px;
  display: inline-block;
  margin-bottom: 12px;
}
