@mixin assign-icon-pos($icon, $size: $pba-spacer, $color-letter: '', $border-color: '') {
  &:before {
    content: $icon;
    font-size: $size + px;
    vertical-align: sub;

    @if $color-letter != '' {
      color: $color-letter;
    }

    @if $border-color != '' {
      background: $border-color;
      border: 4px solid $border-color;
      border-radius: 50%;
    }

    @content;
  }
}

@mixin assign-icon-letter($size, $width, $height, $background, $color) {
  font-size: $size + px;
  vertical-align: sub;
  border-radius: 50%;
  width: $width + px;
  height: $height + px;
  padding: 8px;
  background: $background;
  border: 2px solid #67748c;
  color: $color;
  text-align: center;
  font: 28px Arial, sans-serif;
  display: flex;

  @content;
  // }
}

@mixin assign-icon($name, $icon, $nameSize: 'md', $color-letter: '', $border-color: '') {
  .#{$name}-#{$nameSize} {
    @include assign-icon-pos($icon, map-get($pba-icon-spacers, $nameSize), $color-letter, $border-color) {
      @content;
    }
  }
}

@mixin icon-letter($name, $size, $width, $height, $background, $color) {
  // .#{$name}-#{$nameSize} {
  // @include assign-icon-pos($icon, map-get($pba-icon-spacers, $nameSize), $color-letter, $border-color) {
  // @content;
  // }
  // }
}
