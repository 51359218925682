.ngx-pagination {
  margin-left: 70% !important;
  margin-bottom: 2% !important;
  font-size: 50%;
  .current {
    padding: 0.1875rem 0.625rem;
    background: white !important;
    color: $pba-checked-color !important;
    cursor: pointer;
    border: solid 1px $pba-blue !important;
  }
}
