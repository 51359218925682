.pba-check {
  display: block;
  position: relative;

  &:hover {
    .pba-check-mark {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .pba-check-text,
  .pba-check-mark {
    cursor: pointer;
  }

  .pba-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .pba-check-mark {
        background-color: $pba-color-base;
        border: solid 1px $pba-color-base;

        &:after {
          display: block;
        }
      }
    }

    &:disabled {
      cursor: default;
      background-color: $pba-disabled-color;

      ~ .pba-check-text {
        cursor: default;
      }

      ~ .pba-check-mark {
        background-color: $pba-disabled-color;
        border: solid 1px $pba-disabled-color;
        cursor: default;

        &:after {
          border: solid $pba-gray;
          border-width: 0 3px 3px 0;
        }
      }
    }
  }

  .pba-check-mark {
    position: absolute;
    top: -2px;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    border: solid 1px $pba-disabled-color;
    background-color: $white;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 7px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .pba-check-text {
    padding-left: 26px;
    display: block;
    position: relative;
    font-size: 14px;
    user-select: none;
    color: $pba-text-color;
  }

  .pba-check-error {
    border: solid 1px #ff3a55;
  }
}
