.pba-input--little {
  @include input--little;
}

.pba-input-icon {
  position: absolute;
  right: 0px;
  margin-right: 12px;
  margin-top: 4px;
  color: $pba-text-color;

  & ~ .form-control.is-valid {
    background-position: center right calc(0.375em + 2.6875rem);
  }
}

.pba-input-icon-disabled {
  position: absolute;
  right: 0px;
  margin-right: 12px;
  margin-top: 4px;
  color: $pba-gray;

  & ~ .form-control.is-valid {
    background-position: center right calc(0.375em + 2.6875rem);
  }
}

.pba-input-text {
  background-color: #ffffff;
  color: $pba-text-color;
  height: 48px;
  font-size: 14px;

  &.pba-input-textarea {
    height: 104px;
    resize: none;
  }

  &::placeholder {
    font-size: 14px;
    color: $pba-gray;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &:active,
  &:focus {
    border: solid 1.3px #007de6;
    box-shadow: none;
  }

  &:disabled {
    background-color: $pba-disabled-color;
  }

  &.is-invalid {
    border: solid 1px $pba-error-border-color;
    background-image: none;
  }

  &.is-valid {
    border: solid 1px $pba-success-border-color;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%237acc00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  }
}

.pba-input-search {
  @extend .pba-input-text;
  height: 32px;
  background: $white;
}

.pba-input-label {
  color: $pba-text-color;
  text-align: left;
  display: flex;
  font-size: 11px;

  .pba-input-link {
    text-align: right;
    color: #007de6;
    flex-grow: 1;
  }
}

.pba-input-aux {
  font-size: 11px;
  text-align: left;
  color: #67748c;
}

.pba-input-invalid {
  font-size: 11px;
  text-align: left;
  color: $pba-error-text-color;
}
