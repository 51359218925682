.cdk-group {
  margin-bottom: 4px;

  .cdk-container {
    height: 460px;
    overflow: auto;
    background: #ccc;
    overflow: auto;
    border: solid 1px #ccc;
    min-height: 40px;
    background: white;
    border-radius: 12px;
    display: block;

    &.is-invalid {
      border: solid 1px #ff3a55;
    }

    .cdk-box {
      /* Box Model */
      padding: 12px 10px;
      border-bottom: solid 1px #ccc;
      box-sizing: border-box;

      /* Flexbox */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      /* Typography */
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      text-align: left;

      /* Visual Styles */
      background: white;
      cursor: move;

      &.cdk-drag-disabled {
        background: #d7dce5;
        cursor: default;
      }
    }
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-container.cdk-drop-list-dragging .cdk-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-overlay-container {
  z-index: 3000 !important; //higher then fixed header z-index so it comes above
}
