.btn-toggle {
  background-color: $pba-light-light-gray;

  &.btn-toggle-base-green-red {
    // KO
    background-color: $pba-red;
  }

  &.active {
    background-color: $pba-color-base;

    &.btn-toggle-base-green-red {
      // OK
      background-color: $pba-table-state-ok-color;
    }
  }

  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  border-radius: 28px;

  width: 60px;
  height: 28px;

  &.btn-toggle-small {
    width: 40px;
    height: 16px;
  }

  &:focus,
  &.focus,
  &:focus.active,
  &.focus.active {
    outline: none;
  }

  &:before,
  &:after {
    line-height: 1.5rem;
    width: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }

  &:before {
    content: 'Off';
    left: -0.5rem;
  }

  &:after {
    content: 'On';
    right: -0.5rem;
    opacity: 0.5;
  }

  > .handle {
    position: absolute;
    top: 2px;
    left: 4px;
    width: 21px;
    height: 23px;
    border-radius: 28px;
    background: #fff;
    transition: left 0.25s;
  }

  &.btn-toggle-small {
    > .handle {
      top: 4px;
      left: 5px;
      width: 8px;
      height: 8px;
    }
  }

  &.active {
    transition: background-color 0.25s;

    > .handle {
      left: 2.255rem;
      transition: left 0.25s;
    }

    &.btn-toggle-small {
      > .handle {
        left: 1.5875rem;
      }
    }

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  &.btn-sm {
    &:before,
    &:after {
      line-height: -0.5rem;
      color: #fff;
      letter-spacing: 0.75px;
      font-weight: bold;
      left: 15px;
      width: 36px;
      top: 1px;
      font-size: 11px;
    }

    &.btn-toggle-small {
      &:before,
      &:after {
        left: 9px;
        width: 24px;
        top: -4px;
        font-size: 7px;
      }
    }

    &:before {
      text-align: right;
    }

    &:after {
      text-align: left;
      opacity: 0;
    }

    &.active {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.pba-btn-split {
  background-color: $pba-blue;
  border-radius: 4px;

  &.active {
    background-color: #dfebff;
    box-shadow: none;

    [class^='icon-'],
    [class*=' icon-'] {
      color: #4e9eea;
      vertical-align: middle;
    }
  }

  [class^='icon-'],
  [class*=' icon-'] {
    color: $white;
    vertical-align: middle;
  }
}
